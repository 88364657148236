<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          x-small
          outlined
          color="green"
          :disabled="disabled"
          @click="dialog = !dialog"
          ><v-icon small>add</v-icon></v-btn
        >
      </template>
      <span>Inserir novo editor</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="1000">
      <v-card>
        <v-card-title>
          <span class="title">Inserir novo editor</span>
        </v-card-title>
        <hr />
        <v-card-text class="mt-5">
          <v-row dense justify="start" align="start">
            <v-col cols="12" sm="12" :md="professor == null ? 12 : 6">
              <v-autocomplete
                dense
                outlined
                return-object
                persistent-hint
                hint="Professor"
                :items="professors"
                v-model="professor"
                item-text="name"
                placeholder="Digite um nome..."
                no-data-text="nenhum professor encontrado"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <v-menu
                block
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-show="professor != null && professor != undefined"
                    outlined
                    dense
                    v-model="startDate"
                    append-icon="event"
                    readonly
                    persistent-hint
                    hint="Início"
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu = true"
                    :rules="rules"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menu = false"
                  no-title
                  scrollable
                  locale="br"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <v-menu
                block
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-show="professor != null && professor != undefined"
                    outlined
                    dense
                    v-model="endDate"
                    append-icon="event"
                    readonly
                    persistent-hint
                    hint="Término"
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu2 = true"
                    :rules="rules"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menu2 = false"
                  no-title
                  scrollable
                  locale="br"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-card
                outlined
                class="pa-2"
                v-show="professor != null && professor != undefined"
              >
                <div>
                  <strong>Nome:</strong>
                  {{ professor != null ? professor.name : "" }}
                </div>
                <div>
                  <strong>Email:</strong>
                  {{ professor != null ? professor.email : "" }}
                </div>
                <div>
                  <strong>Sala:</strong>
                  {{ professor != null ? professor.room : "" }}
                </div>
                <div>
                  <strong>Telefone:</strong>
                  {{ professor != null ? professor.phone : "" }}
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-card
                outlined
                class="pa-2"
                v-show="professor != null && professor != undefined"
              >
                <v-checkbox
                  label="Notificar por email?"
                  color="green"
                  v-model="checkbox"
                ></v-checkbox>
              </v-card>
            </v-col>

            <v-col cols="12" class="mt-2">
              <v-progress-linear
                indeterminate
                color="green"
                v-show="progress"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center" align="center" dense class="mb-2">
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn outlined color="red" @click="close()" :disabled="progress"
                >Cancelar<v-icon>clear</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                outlined
                color="green"
                @click="postAsyncEditor()"
                :disabled="progress"
                >Inserir<v-icon>check</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      <v-row justify="center" align="center" class="pa-3">
        {{ snackbarMessage }}
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "EditorInsert",
  props: { disabled: { type: Boolean, default: false } },
  components: {},
  data() {
    return {
      dialog: false,
      professor: null,
      editor: null,
      startMenu: null,
      endMenu: null,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      checkbox: false,
      rules: [(dt) => !!dt || "O campo não pode ser nulo ou vazio!"],
      snackbar: false,
      timeout: 5000,
      snackbarMessage: "",
      color: null,
      progress: false,
    };
  },

  created() {
    this.getAsyncAllProfessors();
  },

  computed: {
    ...mapState("professors", ["professors"]),
  },

  methods: {
    ...mapActions("professors", ["ActionGetAllProfessors"]),
    ...mapActions("editors", ["ActionPostEditor"]),

    async getAsyncAllProfessors() {
      try {
        await this.ActionGetAllProfessors();
      } catch (e) {
        console.error(e);
      }
    },

    async postAsyncEditor() {
      try {
        this.progress = true;

        if (this.validateFields()) {
          let payload = {
            professorId: this.professor.id,
            startedAt: this.startDate + "T08:00:00Z",
            endedAt: this.endDate + "T08:00:00Z",
            sendEmail: this.checkbox,
          };
          await this.ActionPostEditor(payload);
          this.color = null;
          this.snackbarMessage = "Editor inserido com sucesso!";
          this.close();
        } else {
          this.color = "red";
          this.snackbarMessage =
            "É obrigatório selecionar o professor, data de início e data de término!";
        }
      } catch (e) {
        this.color = "red";
        this.snackbarMessage = e.bodyText;
      } finally {
        this.snackbar = true;
        this.progress = false;
      }
    },

    close() {
      this.professor = null;
      this.dialog = false;
      this.progress = false;
      this.checkbox = false;
      this.endDate = null;
      this.startDate = null;
    },

    validateFields() {
      if (
        this.professor == null ||
        this.startDate == null ||
        this.endDate == null
      )
        return false;
      return true;
    },
  },
};
</script>

<style>
</style>