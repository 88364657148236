<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          x-small
          outlined
          color="red"
          @click="dialog = !dialog"
          ><v-icon small>delete</v-icon></v-btn
        >
      </template>
      <span>Remover Editor</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          <span class="title">Remoção de editor</span>
        </v-card-title>
        <hr />
        <v-card-text class="mt-5">
          <v-row dense justify="start" align="start">
            <v-col cols="12" sm="12" class="text-center">
              <div class="subtitle">
                Deseja realmente excluir o editor
                <strong>{{ editor.professor.name }}</strong>
              </div>
              <div>A exclusão é permanente!</div>
            </v-col>

            <v-col cols="12" class="mt-2">
              <v-progress-linear
                indeterminate
                color="green"
                v-show="progress"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center" align="center" dense class="mb-2">
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn outlined color="red" @click="close()" :disabled="progress"
                >Cancelar<v-icon>clear</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                outlined
                color="green"
                :disabled="progress"
                @click="deleteAsyncEditor(editor.id)"
                >Remover<v-icon>delete</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      <v-row justify="center" align="center" class="pa-3">
        {{ snackbarMessage }}
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditorDelete",
  props: {
    editor: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      snackbar: false,
      timeout: 5000,
      snackbarMessage: "",
      color: null,
      progress: false,
    };
  },

  created() {},

  methods: {
    ...mapActions("editors", ["ActionDelEditor"]),

    async deleteAsyncEditor(id) {
      try {
        this.progress = true;
        await this.ActionDelEditor(id);
        this.color = null;
        this.snackbarMessage = "Editor removido com sucesso!";
        this.close();
      } catch (e) {
        this.color = "red";
        this.snackbarMessage = e.bodyText;
      } finally {
        this.snackbar = true;
        this.progress = false;
      }
    },

    close() {
      this.dialog = false;
      this.progress = false;
      this.checkbox = false;
    },
  },
};
</script>

<style>
</style>