<template>
  <v-row justify="start" align="center" dense>
    <v-col cols="12" sm="12" md="3">
      <h3>Editores</h3>
    </v-col>
    <v-col cols="12" sm="12" md="9" class="text--left">
      <EditorInsert :disabled="!isAdmin"></EditorInsert>
    </v-col>
    <v-col cols="12">
      <EditorsTable></EditorsTable>
    </v-col>
    <v-col cols="12"> </v-col>
  </v-row>
</template>

<script>
import EditorsTable from "./EditorsTable.vue";
import EditorInsert from "./EditorInsert.vue";

import * as storage from "@/pages/auth/storage";

export default {
  name: "Editors",
  props: [],
  components: { EditorsTable, EditorInsert },
  data() {
    return {
      user: {},
    };
  },

  computed: {
    isAdmin() {
      return this.user.roles.includes("admin");
    },
  },

  created() {
    this.user = storage.getLocalData().user;
  },
};
</script>

<style>
</style>