var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      "item-key": "id",
      search: _vm.search,
      "hide-default-footer": "",
      items: _vm.items,
      headers: _vm.headers,
      "items-per-page": _vm.items.length,
      "loading-text": "Buscando editores",
      "no-data-text": "Não foram encontrados editores"
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(props) {
          return [
            _c("tr", [
              _c("td", { staticClass: "text-xs-left" }, [
                _vm._v(_vm._s(props.item.professor.name))
              ]),
              _c("td", { staticClass: "text-xs-left" }, [
                _vm._v(_vm._s(_vm.dmy(props.item.startedAt)))
              ]),
              _c("td", { staticClass: "text-xs-left" }, [
                _vm._v(_vm._s(_vm.dmy(props.item.endedAt)))
              ]),
              _c("td", { staticClass: "text-xs-left" }, [
                _vm._v(_vm._s(_vm.status(props.item.enabled)))
              ]),
              _c(
                "td",
                { staticClass: "text-xs-left" },
                [
                  _c("EditorUpdate", {
                    attrs: { editor: props.item, disabled: !_vm.isAdmin }
                  })
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "text-xs-left" },
                [
                  _c("EditorDelete", {
                    attrs: { editor: props.item, disabled: !_vm.isAdmin }
                  })
                ],
                1
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }