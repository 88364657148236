var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c("v-col", { attrs: { cols: "12", sm: "12", md: "3" } }, [
        _c("h3", [_vm._v("Editores")])
      ]),
      _c(
        "v-col",
        { staticClass: "text--left", attrs: { cols: "12", sm: "12", md: "9" } },
        [_c("EditorInsert", { attrs: { disabled: !_vm.isAdmin } })],
        1
      ),
      _c("v-col", { attrs: { cols: "12" } }, [_c("EditorsTable")], 1),
      _c("v-col", { attrs: { cols: "12" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }