<template>
  <v-data-table
    item-key="id"
    :search="search"
    hide-default-footer
    v-bind:items="items"
    v-bind:headers="headers"
    :items-per-page="items.length"
    loading-text="Buscando editores"
    no-data-text="Não foram encontrados editores"
  >
    <template v-slot:item="props">
      <tr>
        <td class="text-xs-left">{{ props.item.professor.name }}</td>
        <td class="text-xs-left">{{ dmy(props.item.startedAt) }}</td>
        <td class="text-xs-left">{{ dmy(props.item.endedAt) }}</td>
        <td class="text-xs-left">{{ status(props.item.enabled) }}</td>
        <td class="text-xs-left">
          <EditorUpdate
            :editor="props.item"
            :disabled="!isAdmin"
          ></EditorUpdate>
        </td>
        <td class="text-xs-left">
          <EditorDelete
            :editor="props.item"
            :disabled="!isAdmin"
          ></EditorDelete>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { formatDate } from "@/utils/formatDate";
import * as storage from "@/pages/auth/storage";

import EditorDelete from "./EditorDelete.vue";
import EditorUpdate from "./EditorUpdate.vue";

export default {
  name: "EditorsTable",
  components: { EditorDelete, EditorUpdate },
  data() {
    return {
      search: "",
      items: [],
      loading: false,
      user: { type: Object, default: {} },
      headers: [
        {
          sortable: true,
          text: "Editor",
          value: "professor.name",
          align: "left",
        },
        {
          sortable: true,
          text: "Início",
          value: "startedDate",
          align: "left",
        },
        {
          sortable: true,
          text: "Término",
          value: "endedDate",
          align: "left",
        },
        {
          sortable: true,
          text: "Ativo",
          value: "enabled",
          align: "left",
        },
        {
          sortable: false,
          text: "Editar",
          value: "edit",
          align: "left",
        },
        {
          sortable: false,
          text: "Remover",
          value: "remove",
          align: "left",
        },
      ],
    };
  },

  created() {
    this.getAsyncEditors();
    this.user = storage.getLocalData().user;
  },

  computed: {
    ...mapState("editors", ["editors"]),

    isAdmin() {
      return this.user.roles.includes("admin");
    },
  },

  methods: {
    ...mapActions("editors", ["ActionGetEditors"]),

    isEditor(item) {
      return this.user.name == item.professor.name;
    },


    async getAsyncEditors() {
      try {
        await this.ActionGetEditors();
      } catch (error) {
        console.error(error);
      }
    },

    dmy(date) {
      return formatDate.dmy(date);
    },

    status(enabled) {
      if (enabled) return "Ativo";
      else return "Inativo";
    },
  },

  watch: {
    editors(newValue) {
      if (newValue != null && newValue != undefined) this.items = newValue;
    },
  },
};
</script>

<style>
</style>