var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            "x-small": "",
                            outlined: "",
                            color: "amber",
                            disabled: _vm.disabled
                          },
                          on: {
                            click: function($event) {
                              _vm.dialog = !_vm.dialog
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", { attrs: { small: "" } }, [_vm._v("edit")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Editar")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "title" }, [_vm._v("Editar")])
              ]),
              _c("hr"),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "start", align: "start" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12" }
                        },
                        [
                          _c("div", [
                            _vm._v(" Desativar o editor "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.editor.professor.name))
                            ]),
                            _vm._v("? ")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mt-2", attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.progress,
                                expression: "progress"
                              }
                            ],
                            attrs: { indeterminate: "", color: "green" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-2",
                      attrs: { justify: "center", align: "center", dense: "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "red",
                                disabled: _vm.progress
                              },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [
                              _vm._v("Cancelar"),
                              _c("v-icon", [_vm._v("clear")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "green",
                                disabled: _vm.progress
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updateAsyncEditor(_vm.editor)
                                }
                              }
                            },
                            [
                              _vm._v("Desativar"),
                              _c("v-icon", [_vm._v("check")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout, color: _vm.color },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pa-3",
              attrs: { justify: "center", align: "center" }
            },
            [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }